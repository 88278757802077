<template v-slot:default>
  <div>
    <thead>
      <tr>
        <th class="text-left">
          Referência
        </th>
        <th class="text-left">
          Peso
        </th>
        <th class="text-left">
          Preço
        </th>
        <th class="text-left">
          Preço Promocional
        </th>
        <th class="text-left">
          Código
        </th>
        <th class="text-left">
          Estoque
        </th>
        <th class="text-left">
          Cor
        </th>
        <th class="text-left">
          Tamanho
        </th>
        <th />
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in configuracoes"
        :key="item.referencia"
        :class="{ 'text-danger': item.errored }"
      >
        <td>{{ item.referencia }}</td>
        <td>{{ item.peso }}</td>
        <td>{{ item.valor | currency }}</td>
        <td>{{ item.valorPromocional | currency }}</td>
        <td>{{ item.idExterno }}</td>
        <td>{{ item.estoque }}</td>
        <td>{{ item.cor.nome }}</td>
        <td>{{ item.tamanho.nome }}</td>
        <td>
          <v-btn
            outlined
            small
            :to="{ path: `/pages/configuracao/produto/${item.id}`, params: { id: item.id }}"
          >
            editar
          </v-btn>
        </td>
      </tr>
    </tbody>
  </div>
</template>

<script>
  export default {
    name: 'ProdutoResumo',
    props: {
      configuracoes: {
        type: Array,
        default: () => ([]),
      },
    },
    data () {
      return {
      }
    },
  }
</script>
